import React from 'react';

import { IconPropsType } from '..';

export const MicrosoftLogo = ({ width, fill }: IconPropsType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 120 120"
    width={width || 800}
    fill={fill || 'currentColor'}>
    <path
      fill={fill || 'currentColor'}
      d="M57.23,15v42.23H15V15h42.23ZM57.23,62.77v42.23H15v-42.23h42.23ZM105,15v42.23h-42.23V15h42.23ZM105,62.77v42.23h-42.23v-42.23h42.23Z"
    />
  </svg>
);
