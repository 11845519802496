import {
  AppleLogo,
  MicrosoftLogo,
  RivianLogo,
  SteelHouseLogo,
  SupergroupLogo,
  TEKSystemsLogo,
  UpkeepLogo,
} from './glyphs';

type Percentage = `${string}%`;

export type IconPropsType = {
  width?: number | Percentage;
  fill?: string;
};

export const Icon = {
  AppleLogo,
  MicrosoftLogo,
  RivianLogo,
  SteelHouseLogo,
  SupergroupLogo,
  TEKSystemsLogo,
  UpkeepLogo,
};
