import { useCallback, useEffect, useState } from 'react';

import { useUserMutations, useStorage } from '.';
import { AlertBarDataType, DialogDataType } from '../components';
import { CompanyIcon, UserAction, getUserDialogTitle } from '../enums';
import {
  UpdateUserMutationInputType,
  UserActionHandlerInputType,
  UserType,
  UserDataInputType,
} from '../types';

const DEFAULT_USER_DATA: UserType = {
  id: process.env.REACT_APP_HOME_PAGE_USER_ID || '',
  firstName: 'Mike',
  lastName: 'Gutierrez',
  biography: `
    With over 7 years of experience in software engineering, I specialize 
    in developing and scaling full-stack web applications within collaborative 
    Agile environments. My core expertise lies in frontend development using 
    Typescript and React, as well as bridging and integrating frontend interfaces 
    with backend microservices through GraphQL API gateways. I am also proficient 
    in building serverless backends with AWS Lambda, Node.js, Python, and DynamoDB. 
    I have been recognized for my leadership and enjoy mentoring teams across 
    various company types, maintaining a commitment to staying current with emerging 
    technologies and programming languages.`,
  location: 'Portland, OR',
  image: 'https://d2pviryyz9qcvf.cloudfront.net/IMG_1066.jpeg',
  intro: {
    title: 'Hello!',
    headline: `I'm Mike Gutierrez, a web engineer located in Portland, Oregon
      specialized in front-end development.`,
    tagline: 'Currently open to new opportunities.',
    experienceSectionCallToAction: 'Explore work',
  },
  experience: [
    {
      title: 'UI Engineer',
      companyName: 'Microsoft · Contract',
      dates: 'Nov 2024 - Present',
      technologies: [
        'Typescript',
        'Fluent UI',
        'React.js',
        'ES6',
        'Continuous Integration and Continuous Delivery (CI/CD)',
        'JavaScript',
        'CSS3',
        'HTML 5',
      ],
      icon: CompanyIcon.MICROSOFT,
      order: 6,
    },
    {
      title: 'Senior Full Stack Software Engineer',
      companyName: 'Apple · Contract',
      dates: 'Nov 2023 - Nov 2024',
      technologies: [
        'React.js',
        'ES6',
        'SQL',
        'Relational Databases',
        'REST APIs',
        'Kubernetes',
        'Unit Testing',
        'Continuous Integration and Continuous Delivery (CI/CD)',
        'JavaScript',
        'CSS3',
        'HTML 5',
      ],
      icon: CompanyIcon.APPLE,
      order: 5,
    },
    {
      title: 'Senior Software Engineer',
      companyName: 'Rivian',
      dates: 'Oct 2021 - Apr 2023',
      technologies: [
        'React.js',
        'TypeScript',
        'GraphQL',
        'JavaScript',
        'Unit Testing',
        'Continuous Integration and Continuous Delivery (CI/CD)',
        'AWS Lambda',
        'AWS AppSync',
        'AWS Dynamodb',
        'Serverless Framework',
        'Python',
        'CSS3',
        'HTML 5',
        'Material-UI',
        'Git',
        'Figma',
      ],
      icon: CompanyIcon.RIVIAN,
      order: 4,
    },
    {
      title: 'Software Engineer II',
      companyName: 'Supergroup™',
      dates: 'Feb 2019 - Oct 2021',
      technologies: [
        'React.js',
        'TypeScript',
        'GraphQL',
        'JavaScript',
        'Node.js',
        'Express.js',
        'Unit Testing',
        'REST APIs',
        'CSS3',
        'HTML 5',
        'Material-UI',
        'Git',
        'Contentful',
        'Shopify',
        'Zeplin',
      ],
      icon: CompanyIcon.SUPERGROUP,
      images: [
        'https://d2pviryyz9qcvf.cloudfront.net/portfolio/AngelCity_5.png',
      ],
      order: 3,
    },
    {
      title: 'Software Engineer',
      companyName: 'UpKeep',
      dates: 'May 2018 - Feb 2019',
      technologies: [
        'React.js',
        'GraphQL',
        'JavaScript',
        'Node.js',
        'Redux',
        'Express.js',
        'LESS',
        'CSS3',
        'HTML 5',
        'Git',
      ],
      icon: CompanyIcon.UPKEEP,
      images: ['https://d2pviryyz9qcvf.cloudfront.net/portfolio/UpKeep_1.jpg'],
      order: 2,
    },
    {
      title: 'Software Engineer',
      companyName: 'SteelHouse',
      dates: 'Apr 2013 - Mar 2017',
      technologies: [
        'React.js',
        'GraphQL',
        'JavaScript',
        'Node.js',
        'Redux',
        'Express.js',
        'LESS',
        'CSS3',
        'HTML 5',
        'jQuery',
        'Git',
        'Ad Tech',
      ],
      icon: CompanyIcon.STEELHOUSE,
      images: [
        'https://d2pviryyz9qcvf.cloudfront.net/portfolio/SteelHouse_AdSuite.jpg',
      ],
      order: 1,
    },
  ],
  about: {
    headline: `Over the past ten years I've learned that writing great software
      requires not only strong programming fundamentals, but also core
      strengths in areas of deep interest.`,
    subheading: `This is why I focus on front-end development and organizational
      principles. Whether a team is looking for a fresh start or maintaining
      a legacy project, I combine an eye for design with a commitment to
      extensibility by developing workflow systems that create easy to use,
      lasting software.`,
    coreSkills: [
      'Typescript',
      'React',
      'GraphQL',
      'CI/CD',
      'AWS infrastructure',
      'Serverless framework',
      'Microservices',
      'Testing',
    ],
  },
};

type Props = {
  data?: UserType;
  isDataFetching: boolean;
  setDialogData: (variables: DialogDataType | null) => void;
  setSnackbarData: (variables: AlertBarDataType | null) => void;
};

type ReturnType = {
  data: {
    isLoading: boolean;
    userData?: UserType;
  };
  dispatchAction: ({ action }: UserActionHandlerInputType) => void;
};

export const useUserData = ({
  data,
  isDataFetching,
  setDialogData,
  setSnackbarData,
}: Props): ReturnType => {
  const [userData, setUserData] = useState<UserType>();

  const {
    errorMessage: storageErrorMessage,
    getImageUrl,
    isLoading: isStorageLoading,
    removeFile,
    uploadFile,
  } = useStorage();

  const {
    updateUser,
    errorMessage: userMutationErrorMessage,
    hasError: userMutationHasError,
    isLoading: isUserMutationLoading,
  } = useUserMutations();

  const handleDefaultUserData = useCallback(
    ({ data }: { data: UserType }) => ({
      id: data?.id || DEFAULT_USER_DATA.id,
      firstName: data?.firstName || DEFAULT_USER_DATA.firstName,
      lastName: data?.lastName || DEFAULT_USER_DATA.lastName,
      biography: data?.biography || DEFAULT_USER_DATA.biography,
      location: data?.location || DEFAULT_USER_DATA.location,
      image: data?.image
        ? getImageUrl({ fileName: data?.image || '' })
        : DEFAULT_USER_DATA.image,
      intro: data?.intro || DEFAULT_USER_DATA.intro,
      about: data?.about || DEFAULT_USER_DATA.about,
      experience: data?.experience || DEFAULT_USER_DATA.experience,
    }),
    [getImageUrl],
  );

  const onUpdateUser = useCallback(
    async ({ input }: { input: UpdateUserMutationInputType }) => {
      const response = await updateUser(input);
      const { user } = response?.data || {};
      if (user) {
        setSnackbarData({
          severity: 'success',
          text: 'Profile updated successfully',
        });
        const updatedUser = handleDefaultUserData({ data: user });
        setUserData(updatedUser);
      } else {
        setSnackbarData({
          severity: 'error',
          text: 'Error updating profile',
        });
      }
      return response;
    },
    [handleDefaultUserData, setSnackbarData, updateUser],
  );

  const onUploadUserImage = useCallback(
    async ({ file, ...user }: UserDataInputType) => {
      if (file) {
        const response = await uploadFile({ file });
        if (response?.success) {
          user.image = response.fileName;
          onUpdateUser({ input: { user } });
        } else {
          setSnackbarData({
            severity: 'error',
            text: 'Error updating image',
          });
        }
        return response;
      }
    },
    [onUpdateUser, setSnackbarData, uploadFile],
  );

  const onRemoveUserImage = useCallback(
    async ({ ...user }: UserDataInputType) => {
      if (user?.image) {
        const response = await removeFile({ fileName: user?.image });
        if (response?.success) {
          user.image = '';
          await onUpdateUser({ input: { user } });
        } else {
          setSnackbarData({
            severity: 'error',
            text: 'Error removing image',
          });
        }
        return response;
      }
    },
    [onUpdateUser, removeFile, setSnackbarData],
  );

  const dispatchAction = useCallback(
    ({ action, data, shouldConfirm = false }: UserActionHandlerInputType) => {
      const executeAction = () => {
        switch (action) {
          case UserAction.UPDATE:
            if (data) {
              onUpdateUser({ input: { user: data } });
            }
            return;
          case UserAction.UPLOAD_IMAGE:
            if (data) {
              onUploadUserImage({ ...data });
            }
            return;
          case UserAction.REMOVE_IMAGE:
            if (data) {
              onRemoveUserImage({ ...data });
            }
            return;
          default: {
            const exhaustiveCase: never = action;
            throw new Error(`Unhandled UserAction case: ${exhaustiveCase}`);
          }
        }
      };

      if (shouldConfirm) {
        setDialogData({
          dialogTitle: getUserDialogTitle({ action }),
          isDialogOpen: true,
          onCloseDialog: () => setDialogData(null),
          onConfirmDialog: () => {
            executeAction();
            setDialogData(null);
          },
        });
      } else {
        executeAction();
      }
    },
    [onRemoveUserImage, onUpdateUser, onUploadUserImage, setDialogData],
  );

  useEffect(() => {
    if (data && !isDataFetching) {
      const user = handleDefaultUserData({ data });
      setUserData(user);
    }
  }, [data, handleDefaultUserData, isDataFetching]);

  useEffect(() => {
    if (userMutationHasError) {
      setSnackbarData({
        severity: 'error',
        text:
          userMutationErrorMessage ??
          `An error occured: ${userMutationErrorMessage}`,
      });
    }
  }, [userMutationHasError, userMutationErrorMessage, setSnackbarData]);

  useEffect(() => {
    if (storageErrorMessage) {
      setSnackbarData({
        severity: 'error',
        text: storageErrorMessage ?? `An error occured: ${storageErrorMessage}`,
      });
    }
  }, [storageErrorMessage, setSnackbarData]);

  return {
    data: {
      isLoading: isUserMutationLoading || isStorageLoading || isDataFetching,
      userData,
    },
    dispatchAction,
  };
};
