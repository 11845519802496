const awsconfig = {
  aws_project_region: 'us-west-1',
  aws_user_pools_id: 'us-west-1_nkgHqgrOp',
  aws_appsync_apiKey: 'da2-jupbh2f7v5c3fcd62as24sa3qy',
  aws_user_pools_web_client_id: '3bcndbpukg2o5ic50omvd9k524',
  aws_appsync_graphqlEndpoint: 'https://4ffveq2ggfdkjkana4gqbgs7z4.appsync-api.us-west-1.amazonaws.com/graphql',
  aws_user_files_s3_bucket_region: 'us-west-1',
  aws_user_files_s3_bucket: 'mikeg-io-images',
  aws_cognito_identity_pool_id: 'us-west-1:9eb0de2b-e3c6-4081-889b-263ef4488b87',
  aws_cognito_region: 'us-west-1',
};

export default awsconfig;
